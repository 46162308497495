<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'servicios.rs_refounds.show',
                  params: { rs_id: id },
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="(data ? data.folio + ' | ' : '') + $route.meta.title"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="warning"
                @click.stop="insuredChangeDlg"
                :disabled="loading"
              >
                <v-icon> mdi-account-switch </v-icon>
              </v-btn>
            </template>
            <span v-text="'Cambiar asegurado'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data && init">
      <v-form v-on:submit.prevent ref="data_form" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'ASEGURADO'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="ID SM / Póliza" :value="data.enrollment" />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="Nombre" :value="data.name" />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="A. Paterno" :value="data.first_surname" />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="A. Materno" :value="data.second_surname" />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <ViewData
                      label="Nombre de oficina"
                      :value="data.office_address"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <ViewData label="Contratante" :value="data.contractor" />
                  </v-col>
                  <v-col v-if="data.insured" cols="12" xs="12" md="3">
                    <ViewData
                      label="F. nacimiento"
                      :value="
                        data.insured.birthday ? data.insured.birthday : '-'
                      "
                    />
                  </v-col>
                  <v-col v-if="data.insured" cols="12" xs="12" md="3">
                    <ViewData
                      label="Vigencia"
                      :value="
                        data.insured.validity ? data.insured.validity : '-'
                      "
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'SOLICITUD'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="Tipo" :value="data.rs_type.type" />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="C. electrónico" :value="data.email" />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="C. electrónico alt." :value="data.email" />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="Teléfono" :value="data.phone" />
                  </v-col>
                  <v-col cols="12">
                    <ViewData
                      label="Detalle de la solicitud"
                      :value="data.detail"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span
                    class="text-caption"
                    v-text="'DIAGNOSTICO DEFINITIVO (ICD)'"
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="11">
                    <v-autocomplete
                      label="Descripción"
                      v-model="icd"
                      :items="icds"
                      :item-text="(item) => `${item.code} | ${item.icd}`"
                      :loading="icds_loading"
                      return-object
                      dense
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          dark
                          small
                          block
                          color="orange darken-2"
                          @click="icdAdd"
                        >
                          <v-icon> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Agregar'" />
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th width="50" v-text="'#'" />
                            <th width="70" v-text="'Código'" />
                            <th v-text="'Descripción'" />
                            <th width="50" v-text="''" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(v, i) in data.icds"
                            :key="i"
                            v-if="v.active"
                          >
                            <td v-text="`${i + 1}`" />
                            <td v-text="v.code" />
                            <td v-text="v.icd" />
                            <td>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    text
                                    icon
                                    x-small
                                    color="error"
                                    @click="icdRemove(i)"
                                  >
                                    <v-icon medium v-text="'mdi-close'" />
                                  </v-btn>
                                </template>
                                <span v-text="`Eliminar ICD (${i + 1})`" />
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col v-if="sinister_rep" cols="12">
                    <v-row dense>
                      <v-col cols="12" />
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                      <v-col cols="12">
                        <h4 v-text="'SINIESTRALIDAD'" />
                      </v-col>
                    </v-row>
                    <v-row dense v-for="(icd, i) in sinister_rep.list" :key="i">
                      <v-col cols="12">
                        <span
                          class="text-caption"
                          v-text="icd.code + ' | ' + icd.icd"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-text="'#'" />
                                <th v-text="'Tipo'" />
                                <th v-text="'Proveedor'" />
                                <th v-text="'T. Prov. | Espec.'" />
                                <th v-text="'Folio'" />
                                <th v-text="'Fecha'" />
                                <th v-text="'Padecimiento'" />
                                <th v-text="'Siniestro'" />
                                <th v-text="'Núm. Siniestro'" />
                                <th v-text="'Deducible'" />
                                <th v-text="'Coaseguro %'" />
                                <th v-text="'Facturado'" />
                                <th v-text="'Monto'" />
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, i) in icd.items" :key="i">
                                <td v-text="`${i + 1}`" />
                                <td v-text="item.type" />
                                <td v-text="item.provider" />
                                <td v-text="item.provider_type" />
                                <td v-text="item.folio" />
                                <td v-text="item.date" />
                                <td
                                  v-text="
                                    item.accident ? 'ACCIDENTE' : 'ENFERMEDAD'
                                  "
                                />
                                <td
                                  v-text="
                                    item.initial ? 'INICIAL' : 'COMPLEMENTARIO'
                                  "
                                />
                                <td v-text="item.sinister_number" />
                                <td
                                  v-text="numberFormat(item.deducible_amount)"
                                />
                                <td v-text="item.coinsurance" />
                                <td>
                                  <v-icon
                                    small
                                    :color="item.bill ? 'success' : ''"
                                  >
                                    mdi-{{ item.bill ? "check" : "close" }}
                                  </v-icon>
                                </td>
                                <td v-text="numberFormat(item.amount)" />
                                <td>
                                  <v-tooltip v-if="item.comment" left>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        fab
                                        dark
                                        x-small
                                        class="ml-1"
                                        color="info"
                                        @click="commentDialog(item)"
                                      >
                                        <v-icon> mdi-message-draw </v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-text="'Seg. médico comentario'" />
                                  </v-tooltip>
                                </td>
                              </tr>
                              <tr>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td>
                                  <b v-text="numberFormat(icd.amount)" />
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'CONSULTAS PENDIENTES'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col v-if="consulting_pendings" cols="12">
                    <v-row
                      dense
                      v-for="(insured, i) in insureds_consulting_pendings"
                      :key="i"
                      v-if="insured.rs_services.length > 0"
                    >
                      <v-col cols="12">
                        <span class="text-caption">
                          {{
                            insured.id == data.insured_id ? "" : "FAMILIAR | "
                          }}ID SM {{ insured.enrollment }} |
                          {{ insured.full_name }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-text="'#'" />
                                <th v-text="'Folio'" />
                                <th v-text="'Padecimiento'" />
                                <th v-text="'Siniestro'" />
                                <th v-text="'Deducible'" />
                                <th v-text="'Coaseguro %'" />
                                <th v-text="'Facturado'" />
                                <th v-text="'Monto'" />
                                <th v-text="'Por cobrar'" />
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(rs_service, j) in insured.rs_services"
                                :key="j"
                                :bgcolor="
                                  rs_service.consulting_pay_original
                                    ? 'yellow'
                                    : ''
                                "
                              >
                                <td v-text="`${j + 1}`" />
                                <td>
                                  {{ rs_service.folio }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        v-if="login.permissions.rss.read"
                                        x-small
                                        icon
                                        color="info"
                                        :to="{
                                          name: 'servicios.cartas',
                                          params: { id: rs_service.rs_id },
                                        }"
                                      >
                                        <v-icon small> mdi-eye </v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-text="'Detalle'" />
                                  </v-tooltip>
                                </td>
                                <td v-text="rs_service.accident" />
                                <td v-text="rs_service.initial" />
                                <td>
                                  <span
                                    v-if="!rs_service.consulting_pay"
                                    v-text="
                                      numberFormat(rs_service.deducible_amount)
                                    "
                                  />
                                  <v-text-field
                                    v-else
                                    v-model="rs_service.deducible_amount"
                                    type="number"
                                    dense
                                    min="0"
                                    @input="calcConsultingAmount(rs_service)"
                                  />
                                </td>
                                <td>
                                  <span
                                    v-if="!rs_service.consulting_pay"
                                    v-text="rs_service.coinsurance"
                                  />
                                  <v-text-field
                                    v-else
                                    v-model="rs_service.coinsurance"
                                    type="number"
                                    dense
                                    min="0"
                                    @input="calcConsultingAmount(rs_service)"
                                  />
                                </td>
                                <td>
                                  <v-icon
                                    small
                                    :color="rs_service.bill ? 'success' : ''"
                                  >
                                    mdi-{{
                                      rs_service.bill ? "check" : "close"
                                    }}
                                  </v-icon>
                                </td>
                                <td v-text="numberFormat(rs_service.amount)" />
                                <td
                                  v-text="
                                    numberFormat(rs_service.consulting_amount)
                                  "
                                />
                                <td>
                                  <v-checkbox
                                    color="info"
                                    v-model="rs_service.consulting_pay"
                                    @click.prevent="
                                      additionalAmount(rs_service)
                                    "
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'SEGUIMIENTO MÉDICO'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        fab
                        x-small
                        color="primary"
                        @click.prevent="insuredHistoryDialog"
                      >
                        <v-icon> mdi-file-sign </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Ver historial médico'" />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-radio-group
                      class="mt-0"
                      v-model="data.accident"
                      :rules="rules.requiredNotNull"
                      row
                    >
                      <v-radio label="Accidente" :value="true" />
                      <v-radio label="Enfermedad" :value="false" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-radio-group
                      class="mt-0"
                      v-model="data.initial"
                      :rules="rules.requiredNotNull"
                      row
                      @change="complementAmount"
                    >
                      <v-radio label="Inicial" :value="true" />
                      <v-radio label="Complemento" :value="false" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0"
                      :label="'Prexistente / Riesgo'"
                      v-model="data.preexisting"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Monto deducible"
                      v-model="data.rs_service.deducible_amount"
                      type="number"
                      :rules="rules.required"
                      dense
                      @input="rsRefoundBillsTotals"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Coaseguro %"
                      v-model="data.rs_service.coinsurance"
                      type="number"
                      :rules="rules.required"
                      dense
                      @input="rsRefoundBillsTotals"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      label="CPT"
                      v-model="data.cpt_id"
                      :items="cpts"
                      :item-text="(v) => `${v.code} | ${v.cpt}`"
                      :loading="cpts_loading"
                      item-value="id"
                      :rules="rules.required"
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      rows="7"
                      label="Comentario"
                      v-model="data.comment"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'FACTURAS'" />
                </v-toolbar-title>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row
                  v-for="(rs_refound_bill, i) in data.rs_refound_bills"
                  :key="i"
                  dense
                >
                  <v-col cols="12" class="grey darken-3 white--text">
                    <v-row dense>
                      <v-col cols="8">
                        <h4
                          v-text="
                            'FACTURA (' +
                            (i + 1) +
                            ') | ' +
                            rs_refound_bill.xml_folio
                          "
                          class="py-1 px-1"
                        />
                      </v-col>
                      <v-col cols="4" class="text-right mt-1">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="white"
                              class="mr-1"
                              :href="
                                url_documents +
                                '/rs_refound_bills/' +
                                rs_refound_bill.url_pdf
                              "
                              target="_blank"
                            >
                              <v-icon> mdi-file-document </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Ver PDF'" />
                        </v-tooltip>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="white"
                              class="mr-1"
                              :href="
                                url_documents +
                                '/rs_refound_bills/' +
                                rs_refound_bill.url_xml
                              "
                              target="_blank"
                            >
                              <v-icon> mdi-xml </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Ver XML'" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <ViewData
                      label="Emisor"
                      :value="
                        rs_refound_bill.emisor_code_tax +
                        ' | ' +
                        rs_refound_bill.emisor_legal_name
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <ViewData
                      :class="rs_refound_bill.code_tax_valid ? '' : 'red--text'"
                      label="Receptor"
                      :value="
                        rs_refound_bill.receptor_code_tax +
                        ' | ' +
                        rs_refound_bill.receptor_legal_name
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <ViewData label="Fecha" :value="rs_refound_bill.xml_date" />
                  </v-col>
                  <v-col
                    v-if="
                      rs_refound_bill.xml_discount &&
                      parseFloat(rs_refound_bill.xml_discount) > 0
                    "
                    cols="12"
                  >
                    <v-alert type="info" dense>
                      <div class="text-center">
                        Incluye un descuento de
                        {{ numberFormat(rs_refound_bill.xml_discount) }}
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th width="40px">#</th>
                            <th>Descripción</th>
                            <th width="150px">Cantidad</th>
                            <th width="150px">Valor unitario</th>
                            <th width="150px">Importe</th>
                            <th width="150px">IVA</th>
                            <th width="150px">Monto reclamado</th>
                            <th width="150px">Gastos cubiertos</th>
                            <th width="150px">Gastos no cubiertos</th>
                            <th width="200px">Observación</th>
                            <th width="40px" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              rs_refound_bill_item, j
                            ) in rs_refound_bill.rs_refound_bill_items"
                            :key="j"
                          >
                            <td v-text="j + 1" />
                            <td v-text="rs_refound_bill_item.item" />
                            <td v-text="rs_refound_bill_item.quantity" />
                            <td
                              v-text="numberFormat(rs_refound_bill_item.amount)"
                            />
                            <td
                              v-text="
                                numberFormat(rs_refound_bill_item.total_amount)
                              "
                            />
                            <td
                              v-text="
                                rs_refound_bill_item.tax +
                                '% | ' +
                                numberFormat(rs_refound_bill_item.tax_amount)
                              "
                            />
                            <td>
                              <b
                                v-text="
                                  numberFormat(
                                    rs_refound_bill_item.total_tax_amount
                                  )
                                "
                              />
                            </td>
                            <td>
                              <span
                                v-if="!rs_refound_bill_item.revision"
                                v-text="
                                  numberFormat(
                                    rs_refound_bill_item.refound_amount
                                  )
                                "
                              />
                              <v-text-field
                                v-else
                                label=""
                                v-model="rs_refound_bill_item.refound_amount"
                                :rules="rules.required"
                                type="number"
                                dense
                                min="0"
                                :max="rs_refound_bill_item.total_tax_amount"
                                @input="rsRefoundBillsTotals"
                              />
                            </td>
                            <td>
                              <b
                                v-text="
                                  numberFormat(
                                    parseFloat(
                                      rs_refound_bill_item.total_tax_amount
                                    ) -
                                      parseFloat(
                                        rs_refound_bill_item.refound_amount
                                      )
                                  )
                                "
                              />
                            </td>
                            <td>
                              <v-autocomplete
                                label=""
                                v-model="
                                  rs_refound_bill_item.rs_refound_item_type_id
                                "
                                :items="rs_refound_item_types"
                                :item-text="(item) => item.rs_refound_item_type"
                                :loading="rs_refound_item_types_loading"
                                item-value="id"
                                dense
                              />
                            </td>
                            <td>
                              <v-simple-checkbox
                                color="success"
                                v-model="rs_refound_bill_item.revision"
                                :ripple="false"
                                @click.prevent="
                                  rsRefoundBillItemSlct(rs_refound_bill_item)
                                "
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <h5 v-text="'TOTALES'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Monto reclamado</th>
                            <th>Gastos cubiertos</th>
                            <th>Gastos no cubiertos</th>
                            <th>Deducible (D)</th>
                            <th>Coaseguro (C)</th>
                            <th>D y C Consultas</th>
                            <th>Pago Reembolso</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td v-text="numberFormat(total_amount)" />
                            <td v-text="numberFormat(covered_amount)" />
                            <td v-text="numberFormat(no_covered_amount)" />
                            <td v-text="numberFormat(deducible_amount)" />
                            <td v-text="numberFormat(coinsurance_amount)" />
                            <td>
                              <v-text-field
                                label=""
                                v-model="additional_amount"
                                :rules="rules.required"
                                type="number"
                                dense
                                min="0"
                                @input="rsRefoundBillsTotals"
                              />
                            </td>
                            <td>
                              <span
                                v-if="data.refound_with_bills"
                                v-text="numberFormat(assured_amount)"
                              />
                              <v-text-field
                                v-else
                                label=""
                                v-model="assured_amount"
                                :rules="rules.required"
                                type="number"
                                dense
                                min="0"
                                @input="rsRefoundAssuredAmount"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" class="py-4">
                    <v-divider />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      rows="3"
                      label="Observación"
                      v-model="data.rs_service.caption"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" xs="12" md="6">
            <v-btn
              x-small
              color="warning"
              @click.prevent="rsRefoundRejectedDlg"
            >
              <v-icon left> mdi-file-cancel </v-icon>
              Rechazar o Datos complementarios
            </v-btn>
          </v-col>
          <v-col cols="12" xs="12" md="6" class="text-right">
            <v-btn dark color="pink darken-2" @click.prevent="medicalRevision">
              GUARDAR
              <v-icon right> mdi-medical-bag </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-dialog
      v-model="insured_history_dialog"
      scrollable
      persistent
      max-width="900"
    >
      <v-card
        tile
        :disabled="insured_history_dialog_loading"
        :loading="insured_history_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> HISTORIAL MÉDICO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_history_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insured_history_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'ASEGURADO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          :color="
                            (insured_history_data.insured.medical_treatment
                              ? 'pink '
                              : 'grey') + ' darken-1'
                          "
                          dark
                          icon
                          @click.prevent="insuredMedicalTreatmentHandle"
                        >
                          <v-icon> mdi-shield-account </v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          (insured_history_data.insured.medical_treatment
                            ? 'Con'
                            : 'Sin') + ' tratamiento médico'
                        "
                      />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Nombre"
                        :value="
                          insured_history_data.insured.name +
                          ' ' +
                          insured_history_data.insured.first_surname +
                          ' ' +
                          insured_history_data.insured.second_surname
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="ID SM"
                        :value="insured_history_data.insured.enrollment"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Núm. asegurado"
                        :value="insured_history_data.insured.credential"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="F. nacimineto"
                        :value="insured_history_data.insured.birthday"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Vigencia"
                        :value="insured_history_data.insured.validity"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Contratante"
                        :value="insured_history_data.insured.contractor.name"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'DETALLE'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row
                    v-for="(rs, i) in insured_history_data.rss"
                    :key="i"
                    dense
                  >
                    <v-col cols="12">
                      <v-spacer />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        :label="
                          'Folio (' +
                          (insured_history_data.rss.length - i) +
                          ')'
                        "
                        :value="rs.folio"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData label="F. creación" :value="rs.created_at" />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <ViewData label="Comentario" :value="rs.comment" />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <v-row v-for="(icd, j) in rs.icds" :key="j" dense>
                        <v-col cols="2">
                          <ViewData
                            :label="'ICD (' + (j + 1) + ')'"
                            :value="icd.icd.code"
                          />
                        </v-col>
                        <v-col cols="10">
                          <ViewData label="Descripción" :value="icd.icd.icd" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="comment_dialog" scrollable persistent max-width="600px">
      <v-card v-if="comment" tile>
        <v-toolbar dark dense>
          <v-toolbar-title> FOLIO {{ comment.folio }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="comment_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'SEG. MÉDICO COMENTARIO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p v-text="comment.comment" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="insured_change_dlg"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        tile
        :disabled="insured_change_dlg_ldg"
        :loading="insured_change_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> CAMBIAR ASEGURADO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_change_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insured_change">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="insured_change_hdrs"
                :items="insured_change.insureds"
                :items-per-page="15"
                dense
              >
                <template v-slot:item.index="{ item, index }">
                  <b v-text="index + 1" />
                </template>
                <template v-slot:item.action="{ item }">
                  <div class="text-right">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="warning"
                          x-small
                          icon
                          class="ml-1"
                          @click.prevent="insuredChangeHandle(item)"
                        >
                          <v-icon> mdi-send </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Seleccionar'" />
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rs_refound_rejected_dlg"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        tile
        :disabled="rs_refound_rejected_dlg_ldg"
        :loading="rs_refound_rejected_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> RECHAZAR O DATOS COMPLEMENTARIOS</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rs_refound_rejected_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rs_refound_rejected">
          <v-form
            v-on:submit.prevent
            ref="rs_refound_rejected_form"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-spacer />
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Tipo"
                  v-model="rs_refound_rejected.rs_refound_rejected_type_id"
                  item-value="id"
                  :items="rs_refound_rejected_types"
                  :item-text="(item) => item.rs_refound_rejected_type"
                  :loading="rs_refound_rejected_types_ldg"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  rows="3"
                  label="Observación"
                  v-model="rs_refound_rejected.observation"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  small
                  color="warning"
                  @click.prevent="rsRefoundRejectedSubmit"
                >
                  Enviar
                  <v-icon right> mdi-send </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  msgConfirm,
  URL_DOCUMENTS,
  URL_PDF,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      loading: true,
      id: parseInt(this.$route.params.id),
      login: this.$store.getters.getLogin,
      data: null,
      rules: rules(),
      url_documents: URL_DOCUMENTS,
      insured_history_data: null,
      insured_history_dialog: false,
      insured_history_dialog_loading: false,
      icds: [],
      icds_loading: true,
      icd_loading: false,
      icd: null,
      rs_refound_item_types: [],
      rs_refound_item_types_loading: true,
      sinister_rep: null,
      cpts: [],
      cpts_loading: true,
      deducible_amount: "0",
      total_amount: 0,
      covered_amount: 0,
      no_covered_amount: 0,
      deducible_amount: 0,
      coinsurance_amount: 0,
      additional_amount: "0",
      assured_amount: 0,
      consulting_pendings: [],
      comment: null,
      comment_dialog: false,
      init: false,
      rs_refound_rejected_types: [],
      rs_refound_rejected_types_ldg: true,
      rs_refound_rejected: null,
      rs_refound_rejected_dlg: false,
      rs_refound_rejected_dlg_ldg: false,
      insured_change: null,
      insured_change_dlg_ldg: false,
      insured_change_dlg: false,
      insured_change_hdrs: [
        {
          text: "#",
          value: "index",
          filterable: false,
          width: "50",
        },
        {
          text: "ID SM",
          value: "enrollment",
          filterable: true,
        },
        {
          text: "N. credencial",
          value: "credential",
          filterable: true,
        },
        {
          text: "Asegurado",
          value: "full_name",
          filterable: true,
        },
        {
          text: "Vigencia",
          value: "validity",
          filterable: true,
        },
        {
          text: "Contratante",
          value: "contractor_name",
          filterable: true,
        },
        {
          text: "Oficina",
          value: "office_address",
          filterable: true,
        },
        {
          text: "",
          value: "action",
          width: "200",
          sortable: false,
          fixed: true,
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;

      Axios.get(
        URL_API + `/rss/${this.id}/3`,
        headersToken(this.login.token)
      ).then((res) => {
        this.data = res.data.data;
        this.data.accident =
          this.data.accident == null ? null : this.data.accident == 1;
        this.data.initial =
          this.data.initial == null ? null : this.data.initial == 1;
        this.data.preexisting =
          this.data.preexisting == null ? null : this.data.preexisting == 1;

        let last_rs_service = null;
        if (this.data.services.length > 0) {
          last_rs_service = this.data.services[this.data.services.length - 1];
        }

        this.data.rs_service = {
          id: null,
          active: true,
          rs_service_type_id: 3,
          specialty_type_id: null,
          provider_type_id: null,
          entity_id: null,
          municipality_id: null,
          zip: "",
          doctor_id: null,
          consulting_room_id: null,
          doctor_specialty: null,
          provider_id: null,
          agreement: false,
          confirm_consultation: 0,
          confirm_consultation_by_id: null,
          confirm_consultation_at: null,
          medical_revision: true,
          benefits: [],
          benefits_amount: 0,
          auto_letter_generated: 0,
          //------
          deducible_amount: "0",
          coinsurance: "0",
          coinsurance_amount: 0,
          assured_amount: 0,
          additional_amount: "0",
          caption: "",
          //-----
          total_amount: 0,
          covered_amount: 0,
          no_covered_amount: 0,
        };

        this.data.rs_service.deducible_amount = String(
          this.data.insured.contractor.amount_deductible
        );
        this.deducible_amount = this.data.rs_service.deducible_amount;

        this.data.rs_service.coinsurance = String(
          this.data.insured.contractor.percentage_coinsurance
        );

        //this.data.rs_service.caption = this.data.insured.contractor.caption;
        // this.max_coinsurance = parseFloat(
        //   this.data.insured.contractor.max_coinsurance
        // );

        Axios.get(
          URL_API + `/rs/refound/bills?rs_id=${this.id}`,
          headersToken(this.login.token)
        ).then((res) => {
          this.data.rs_refound_bills = res.data.data;

          for (let rs_refound_bill of this.data.rs_refound_bills) {
            for (let rs_refound_bill_item of rs_refound_bill.rs_refound_bill_items) {
              rs_refound_bill_item.revision =
                rs_refound_bill_item.revision == 1;
            }
          }

          this.rsRefoundBillsTotals();

          if (last_rs_service) {
            this.data.rs_service.assured_amount =
              last_rs_service.assured_amount_original;
            this.assured_amount = this.data.rs_service.assured_amount;
            this.data.rs_service.additional_amount =
              last_rs_service.additional_amount;
            this.additional_amount = this.data.rs_service.additional_amount;
            this.data.rs_service.caption = last_rs_service.caption;
          }

          //OLD FUNCTION CONSULTING PENDINGS
          // Axios.get(
          //   URL_API +
          //     `/insureds/consulting/pendings?insured_id=${this.data.insured_id}`,
          //   headersToken(this.login.token)
          // ).then((res) => {
          //   this.insureds_consulting_pendings = res.data.data;
          //   this.init = true;
          //   this.loading = false;
          // });
          Axios.get(
            URL_API +
              "/rss/refound/insured/" +
              this.data.insured_id +
              "/consulting_pendings",
            headersToken(this.login.token)
          ).then((res) => {
            this.insureds_consulting_pendings = res.data.data;
            this.init = true;
            this.loading = false;
          });
        });
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    rsRefoundBillItemSlct(item) {
      if (item.revision) {
        item.refound_amount = item.total_tax_amount;
      } else {
        item.refound_amount = 0;
      }

      this.rsRefoundBillsTotals();
    },
    rsRefoundBillsTotals() {
      this.data.rs_service.total_amount = 0;
      this.data.rs_service.covered_amount = 0;
      this.data.rs_service.no_covered_amount = 0;
      this.data.rs_service.assured_amount = 0;

      for (let rs_refound_bill of this.data.rs_refound_bills) {
        for (let rs_refound_bill_item of rs_refound_bill.rs_refound_bill_items) {
          this.data.rs_service.total_amount += parseFloat(
            rs_refound_bill_item.total_tax_amount
          );
          this.data.rs_service.covered_amount += parseFloat(
            rs_refound_bill_item.refound_amount
          );
          this.data.rs_service.no_covered_amount +=
            parseFloat(rs_refound_bill_item.total_tax_amount) -
            parseFloat(rs_refound_bill_item.refound_amount);
        }
      }

      this.data.rs_service.coinsurance_amount =
        (this.data.rs_service.covered_amount -
          parseFloat(this.data.rs_service.deducible_amount)) *
        (this.data.rs_service.coinsurance / 100);

      this.data.rs_service.coinsurance_amount =
        this.data.rs_service.coinsurance_amount < 0
          ? 0
          : this.data.rs_service.coinsurance_amount;

      this.data.rs_service.assured_amount =
        this.data.rs_service.covered_amount -
        parseFloat(this.data.rs_service.deducible_amount) -
        this.data.rs_service.coinsurance_amount -
        parseFloat(this.additional_amount);

      this.data.rs_service.assured_amount =
        this.data.rs_service.assured_amount < 0
          ? 0
          : this.data.rs_service.assured_amount;

      this.total_amount = this.data.rs_service.total_amount;
      this.covered_amount = this.data.rs_service.covered_amount;
      this.no_covered_amount = this.data.rs_service.no_covered_amount;
      this.deducible_amount = this.data.rs_service.deducible_amount;
      this.coinsurance_amount = this.data.rs_service.coinsurance_amount;
      this.assured_amount = this.data.rs_service.assured_amount;

      this.data.rs_service.additional_amount = parseFloat(
        this.additional_amount
      );

      this.$forceUpdate();
    },
    rsRefoundAssuredAmount() {
      this.data.rs_service.assured_amount = this.assured_amount;
    },
    insuredHistoryDialog() {
      this.insured_history_data = null;
      this.insured_history_dialog_loading = true;
      this.insured_history_dialog = true;
      Axios.get(
        `${URL_API}/rss/${this.id}/insured/${this.data.insured_id}/history/icd`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.insured_history_data = resp.data.data;
        this.insured_history_dialog_loading = false;
      });
    },
    insuredMedicalTreatmentHandle() {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma " +
              (this.insured_history_data.insured.medical_treatment
                ? "quitar de"
                : "poner en") +
              " tratamiento al asegurado?"
          )
        )
        .then((resp) => {
          if (resp.isConfirmed) {
            this.insured_history_dialog_loading = true;

            Axios.post(
              URL_API + "/insureds/medical/treatment",
              {
                id: this.insured_history_data.insured.id,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.insured_history_data.insured.medical_treatment =
                  resp.data.medical_treatment;
              }

              this.insured_history_dialog_loading = false;
            });
          }
        });
    },
    icdAdd() {
      this.icd_loading = true;

      if (this.icd) {
        let icd_exists = false;

        for (let i = 0; i < this.data.icds.length; i++) {
          if (
            this.data.icds[i].icd_id == this.icd.id &&
            this.data.icds[i].active
          ) {
            icd_exists = true;
          }
        }

        if (!icd_exists) {
          this.data.icds.push({
            id: null,
            icd_id: this.icd.id,
            code: this.icd.code,
            icd: this.icd.icd,
            active: true,
          });

          if (this.data.icds.length == 1) {
            this.icds_loading = true;

            Axios.get(
              URL_API +
                "/insureds/sinister/report?insured_id=" +
                this.data.insured_id +
                "&icd_id=" +
                this.icd.id,
              headersToken(this.login.token)
            ).then((res) => {
              this.sinister_rep = res.data.data;
              this.icds_loading = false;
            });
          }
        } else {
          this.$swal.fire(
            msgAlert("warning", "El ICD seleccionado ya ha sido agregado")
          );
        }
      } else {
        this.$swal.fire(msgAlert("error", "Selecciona una descripción de ICD"));
      }

      this.icd_loading = false;
    },
    icdRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar ICD (${i + 1})?`))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.data.icds[i].id !== null) {
              this.data.icds[i].active = false;
            } else {
              this.data.icds.splice(i, 1);
            }
          }

          if (this.data.icds.length == 0) {
            this.sinister_rep = null;
          }
        });
    },
    complementAmount() {
      if (this.data.initial) {
        this.data.rs_service.deducible_amount = this.deducible_amount;
      } else {
        this.data.rs_service.deducible_amount = "0";
      }

      this.rsRefoundBillsTotals();
    },
    medicalRevision() {
      if (this.data.icds.length > 0) {
        if (this.$refs.data_form.validate()) {
          this.$swal
            .fire(msgConfirm("Confirma el seguimiento médico de reembolso"))
            .then((res) => {
              if (res.isConfirmed) {
                this.loading = true;

                let rs_services = [];
                for (let insured of this.insureds_consulting_pendings) {
                  for (let rs_service of insured.rs_services) {
                    if (rs_service.consulting_pay) {
                      rs_services.push({
                        id: rs_service.id,
                        deducible_amount: rs_service.deducible_amount,
                        coinsurance: rs_service.coinsurance,
                      });
                    }
                  }
                }

                let obj = {
                  rs_id: this.data.id,
                  accident: this.data.accident,
                  initial: this.data.initial,
                  preexisting:
                    this.data.preexisting == null
                      ? false
                      : this.data.preexisting,
                  comment: this.data.comment,
                  cpt_id: this.data.cpt_id,
                  icds: this.data.icds,
                  rs_refound_bills: this.data.rs_refound_bills,
                  rs_services: rs_services,
                  current_rs_services: this.data.services,
                  rs_service: this.data.rs_service,
                };

                Axios.post(
                  URL_API + "/rs/refound_medical_revision",
                  obj,
                  headersToken(this.login.token)
                ).then((res) => {
                  this.$swal.fire(
                    msgAlert(
                      res.data.success ? "success" : "error",
                      res.data.message
                    )
                  );

                  if (res.data.success) {
                    // window
                    //   .open(
                    //     URL_PDF + "/rs/refound/bills/" + obj.rs_id,
                    //     "_blank"
                    //   )
                    //   .focus();

                    this.$router.push({
                      name: "servicios.rs_refounds.show",
                      params: {
                        rs_id: this.id,
                      },
                    });
                  }

                  this.loading = false;
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Formulario invalido, revisa los detalles señalados"
            )
          );
        }
      } else {
        this.$swal.fire(msgAlert("error", "Se debe de agregar mínimo un ICD"));
      }
    },
    additionalAmount(rs_service) {
      if (rs_service) {
        this.calcConsultingAmount(rs_service);
      }

      let additional_amount = 0;

      for (let insured of this.insureds_consulting_pendings) {
        for (let rs_service of insured.rs_services) {
          if (rs_service.consulting_pay) {
            additional_amount += parseFloat(rs_service.consulting_amount);
          } else {
            rs_service.deducible_amount = rs_service.deducible_amount_original;
            rs_service.coinsurance = rs_service.coinsurance_original;
          }
        }
      }

      this.additional_amount = String(additional_amount);
      this.data.rs_service.additional_amount = additional_amount;
      this.$forceUpdate();
    },
    calcConsultingAmount(rs_service) {
      rs_service.deducible_amount = !rs_service.deducible_amount
        ? "0"
        : rs_service.deducible_amount;
      rs_service.coinsurance = !rs_service.coinsurance
        ? "0"
        : rs_service.coinsurance;

      let consulting_amount = 0;
      const amount = parseFloat(rs_service.amount);
      const deducible_amount = parseFloat(rs_service.deducible_amount);
      const coinsurance = parseInt(rs_service.coinsurance);

      if (amount > 0) {
        consulting_amount =
          Math.abs((amount - deducible_amount) * (coinsurance / 100)) +
          deducible_amount;
      }
      rs_service.consulting_amount = String(consulting_amount);

      this.$forceUpdate();

      this.additionalAmount(null);
    },
    commentDialog(item) {
      this.comment = item;
      this.comment_dialog = true;
    },
    rsRefoundRejectedDlg() {
      this.rs_refound_rejected = {
        observation: "",
        rs_refound_rejected_type_id: null,
        rs_id: this.id,
      };
      this.rs_refound_rejected_dlg_ldg = false;
      this.rs_refound_rejected_dlg = true;
    },
    rsRefoundRejectedSubmit() {
      if (this.$refs.rs_refound_rejected_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma enviar observación?"))
          .then((res) => {
            if (res.isConfirmed) {
              this.rs_refound_rejected_dlg_ldg = true;

              Axios.post(
                URL_API + "/rs/refound/rejected",
                this.rs_refound_rejected,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.$router.push({
                    name: "servicios.rs_refounds.show",
                    params: {
                      rs_id: this.id,
                    },
                  });
                }

                this.rs_refound_rejected_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    insuredChangeDlg() {
      this.insured_change = {
        insureds: [],
        insured_id: this.data.insured.id,
      };
      this.insured_change_dlg_ldg = true;
      this.insured_change_dlg = true;

      Axios.get(
        URL_API +
          "/insureds/" +
          this.insured_change.insured_id +
          "/search_family",
        headersToken(this.login.token)
      ).then((res) => {
        this.insured_change.insureds = res.data.data;
        this.insured_change_dlg_ldg = false;
      });
    },
    insuredChangeHandle(item) {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma hacer el cambio por el asegurado con ID SM " +
              item.enrollment +
              "?"
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.insured_change_dlg_ldg = true;

            Axios.post(
              URL_API + "/rss/change/insured",
              {
                insured_id: item.id,
                rs_id: this.data.id,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.insured_change_dlg = false;
                this.getData();
              }

              this.insured_change_dlg_ldg = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=icds&no_order=false`,
      headersToken(this.login.token)
    ).then((response) => {
      this.icds = response.data.data;
      this.icds_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=cpts&no_order=false`,
      headersToken(this.login.token)
    ).then((response) => {
      this.cpts = response.data.data;
      this.cpts_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=rs_refound_item_types&no_order=false`,
      headersToken(this.login.token)
    ).then((response) => {
      this.rs_refound_item_types = response.data.data;
      this.rs_refound_item_types.push({
        id: null,
        rs_refound_item_type: "N/A",
      });
      this.rs_refound_item_types_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=rs_refound_rejected_types&no_order=false`,
      headersToken(this.login.token)
    ).then((res) => {
      this.rs_refound_rejected_types = res.data.data;
      this.rs_refound_rejected_types_ldg = false;
    });

    this.getData();
  },
};
</script>